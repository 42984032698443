import flatten from "lodash/flatten";
import React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { colors, styled } from "@puzzle/theme";
import { Check, Subtract } from "@puzzle/icons";
import rgba from "polished/lib/color/rgba";

const checkedSelectors = flatten(
  [
    "[aria-pressed=true]",
    "[aria-checked=true]",
    "[aria-selected=true]",
    "[aria-checked=mixed]",
    "[data-state=indeterminate]",
  ].map((x) => `&${x}`)
).join(", ");

const disabledGray = rgba(colors.neutral800, 0.56);
const Root = styled(CheckboxPrimitive.Root, {
  all: "unset",
  boxSizing: "border-box",
  cursor: "pointer",
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  backgroundColor: "transparent",
  border: `1px solid ${colors.neutral400}`,
  borderRadius: "4px",

  color: colors.black,

  svg: {
    width: "100%",
    height: "auto",
  },

  "&[data-interactive=true]": {
    "&:hover": {
      borderColor: "$mauve500",
    },

    "&:focus": {
      borderColor: "$green600",
    },

    "&:active, button:focus-within:active > &": {
      backgroundColor: "$green700",
      borderColor: "$green700",
    },
  },

  [checkedSelectors]: {
    backgroundColor: "$green600",
    borderColor: "$green600",

    "&:hover, &:focus": {
      borderColor: "$green800",
    },
  },

  "&[aria-disabled=true]": {
    cursor: "initial",
    // TODO this is probably rgb for some $gray token
    backgroundColor: disabledGray,
    pointerEvents: "none",

    [checkedSelectors]: {
      backgroundColor: "$neutral600",
    },

    "&, &:hover, &:focus, &:active": {
      borderColor: "$neutral400",
    },

    "&:active": {
      backgroundColor: disabledGray,
    },
  },
});

const Indicator = styled(CheckboxPrimitive.Indicator, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: colors.black,
});

export const Checkbox = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Root> & {
    as?: string | (() => React.ReactNode);
    size?: number;
    interactive?: boolean;
    disabled?: boolean;
  }
>(({ as, size = 14, css, interactive, ...props }, ref) => {
  const Component = as || "button";

  return (
    <Root
      {...props}
      asChild
      css={{ ...css, width: size, height: size }}
      ref={ref}
      aria-disabled={props.disabled}
      data-interactive={interactive ?? true}
    >
      {/** @ts-expect-error fix `as` type */}
      <Component>
        <Indicator>
          {props.checked === "indeterminate" ? <Subtract width={8} /> : <Check />}
        </Indicator>
      </Component>
    </Root>
  );
});

Checkbox.toString = Root.toString;
