import rgba from "polished/lib/color/rgba";

export const baseColors = {
  black: "#000000",
  white: "#FFFFFF",
  neutral: "#121221",

  blue100: "#2DB2E0",
  blue200: "#5AD4FF",
  blue300: "#4FB1F9",
  blue400: "#5B9EFF",
  blue500: "#63C5FF",

  gray50: "#F8F8FA",
  gray100: "#E8E8EA",
  gray200: "#D1D1D5",
  gray300: "#B3B2B8",
  gray400: "#95949B",
  gray500: "#77767E",
  gray600: "#595861",
  gray700: "#3C3B44",
  gray800: "#1E1D26",

  brown500: "#2A292E",
  brown800: "#A35200",

  green60: "#206444",
  green100: "#EBFFFC",
  green200: "#C9FFF1",
  green300: "#ADFFE2",
  green400: "#94FFCF",
  green500: "#81FFBB",
  green600: "#72F2A6",
  green700: "#62D89A",
  green800: "#52BD8D",

  mauve100: "#736285",
  mauve200: "#605172",
  mauve300: "#544868",
  mauve400: "#4A405E",
  mauve500: "#3F3754",
  mauve550: "#353547",
  mauve600: "#352F4A",
  mauve650: "#302F4A",
  mauve680: "#2D2D40",
  mauve700: "#2B2740",
  mauve800: "#211F35",
  mauve850: "#1C1B2E",
  mauve900: "#171629",
  mauve950: "#21212E",

  purple100: "#FDF5FF",
  purple200: "#F3D8FF",
  purple300: "#E5BDFC",
  purple400: "#D3A5F7",
  purple500: "#C08FEE",
  purple600: "#AB7DE3",
  purple700: "#966DD5",
  purple800: "#7A5AB9",
  purple900: "#60479C",

  red100: "#FFEDEB",
  red200: "#FFA8AE",
  red300: "#FF8395",
  red400: "#F74B75",
  red500: "#E7366C",
  red600: "#CB2863",
  red700: "#AC1D58",
  red800: "#8C134C",
  red900: "#780038",
  red1000: "#A61807",

  yellow100: "#FFFFD5",
  yellow200: "#FFFF92",
  yellow300: "#FFF076",
  yellow350: "#F9E280",
  yellow400: "#F7DB60",
  yellow500: "#E8C34D",
  yellow600: "#CBA13D",
  yellow650: "#94833A",
  yellow700: "#AC802E",
  yellow800: "#8B6121",

  rhino700: "#353548",
  rhino750: "#343445",
  rhino800: "#282939", // not in jigsaw v1. Made by mixing rhino700 and rhino900
  rhino900: "#1b1c29", // this color is not specified in Jigsaw v1's Figma file, but is used all over older parts of the app

  greenalpha: "#50FAAB",

  // Base colors for the new jigsaw v2 color tokens
  pink500: "#FF63EF",
  orange500: "#FC7413",
};

export const jigsawV2Colors = {
  black25: rgba(baseColors.black, 0.025),
  black50: rgba(baseColors.black, 0.05),
  black100: rgba(baseColors.black, 0.1),
  black200: rgba(baseColors.black, 0.2),
  black300: rgba(baseColors.black, 0.3),
  black400: rgba(baseColors.black, 0.4),
  black500: rgba(baseColors.black, 0.5),
  black600: rgba(baseColors.black, 0.6),
  black700: rgba(baseColors.black, 0.7),
  black800: rgba(baseColors.black, 0.8),
  black900: rgba(baseColors.black, 0.9),

  white25: rgba(baseColors.white, 0.025),
  white50: rgba(baseColors.white, 0.05),
  white100: rgba(baseColors.white, 0.1),
  white200: rgba(baseColors.white, 0.2),
  white300: rgba(baseColors.white, 0.3),
  white400: rgba(baseColors.white, 0.4),
  white500: rgba(baseColors.white, 0.5),
  white600: rgba(baseColors.white, 0.6),
  white700: rgba(baseColors.white, 0.7),
  white800: rgba(baseColors.white, 0.8),
  white900: rgba(baseColors.white, 0.9),

  pink100V2: "#FFE0FC",
  pink200V2: "#FFC1F9",
  pink300V2: "#FFA1F5",
  pink400V2: "#FF82F2",
  pink500V2: "#FF63EF",
  pink600V2: "#CC4FBF",
  pink700V2: "#993B8F",
  pink800V2: "#662860",
  pink900V2: "#331430",

  red100V2: "#FAD7E2",
  red200V2: "#F5AFC4",
  red300V2: "#F186A7",
  red400V2: "#EC5E89",
  red500V2: "#E7366C",
  red600V2: "#B92B56",
  red700V2: "#8B2041",
  red800V2: "#5C162B",
  red900V2: "#2E0B16",

  blue100V2: "#E0F3FF",
  blue200V2: "#C1E8FF",
  blue300V2: "#A1DCFF",
  blue400V2: "#82D1FF",
  blue500V2: "#63C5FF",
  blue600V2: "#4F9ECC",
  blue700V2: "#3B7699",
  blue800V2: "#284F66",
  blue900V2: "#142733",

  purple100V2: "#F2E9FC",
  purple200V2: "#E6D2F8",
  purple300V2: "#D9BCF5",
  purple400V2: "#CDA5F1",
  purple500V2: "#C08FEE",
  purple600V2: "#9A72BE",
  purple700V2: "#73568F",
  purple800V2: "#4D395F",
  purple900V2: "#261D30",

  orange100V2: "#FEE3D0",
  orange200V2: "#FEC7A1",
  orange300V2: "#FDAC71",
  orange400V2: "#FD9042",
  orange500V2: "#FC7413",
  orange600V2: "#CA5D0F",
  orange700V2: "#97460B",
  orange800V2: "#652E08",
  orange900V2: "#321704",

  yellow100V2: "#FDF8DF",
  yellow200V2: "#FCF1BF",
  yellow300V2: "#FAE9A0",
  yellow400V2: "#F9E280",
  yellow500V2: "#F7DB60",
  yellow600V2: "#C6AF4D",
  yellow700V2: "#94833A",
  yellow800V2: "#635826",
  yellow900V2: "#312C13",

  // Lookout: the green base color has changed into greenalpha

  green100V2: "#DCFEEE",
  green200V2: "#B9FDDD",
  green300V2: "#96FCCD",
  green400V2: "#73FBBC",
  green500V2: "#50F9AB",
  green600V2: "#40C889",
  green700V2: "#309667",
  green800V2: "#206444",
  green900V2: "#103222",

  neutral25: "#F9F9FA",
  neutral50: "#F3F3F4",
  neutral100: "#E8E8EA",
  neutral200: "#D1D0D4",
  neutral300: "#B9B9BF",
  neutral400: "#A2A2A9",
  neutral500: "#8B8A94",
  neutral600: "#74737F",
  neutral700: "#5D5C69",
  neutral800: "#454554",
  neutral900: "#171629",
  neutral: "#121221",
};

export const colorsToConsolidate = {
  gray50A04: rgba(jigsawV2Colors.neutral50, 0.04),
  gray50A16: rgba(jigsawV2Colors.neutral50, 0.16),
};

// Naming schema:
// [elementType]-[priority/mood]-[level]
// [elementType] is the type of element that the color is used on: surface, outline, text
// [priority/mood] is the priority or mood of the color: primary, secondary, tertiary, error, positive, warning, special
// [level] is the level of the color: moreSubdue, subdue, base, highlight, moreHighlight, mostHighlight
//
// Example:
// surface-primary-moreSubdue
// text-monetize-subdue
// outline-special-base
const surface = {
  primary: {
    moreSubdue: jigsawV2Colors.green900V2,
    subdue: jigsawV2Colors.green600V2,
    base: jigsawV2Colors.green500V2,
    highlight: jigsawV2Colors.green400V2,
  },
  monetize: {
    moreSubdue: jigsawV2Colors.yellow900V2,
    subdue: jigsawV2Colors.yellow700V2,
    base: jigsawV2Colors.yellow500V2,
    highlight: jigsawV2Colors.yellow400V2,
  },
  error: {
    moreSubdue: jigsawV2Colors.red900V2,
    subdue: jigsawV2Colors.red700V2,
    base: jigsawV2Colors.red500V2,
    highlight: jigsawV2Colors.red400V2,
  },
  warning: {
    moreSubdue: jigsawV2Colors.orange900V2,
    subdue: jigsawV2Colors.orange700V2,
    base: jigsawV2Colors.orange500V2,
    highlight: jigsawV2Colors.orange300V2,
  },
  info: {
    mostSubdue: jigsawV2Colors.blue900V2,
    base: jigsawV2Colors.blue500V2,
  },

  alphaInverted: {
    subdue: jigsawV2Colors.black100,
    base: jigsawV2Colors.black200,
    highlight: jigsawV2Colors.black400,
  },
  alpha: {
    moreSubdue: jigsawV2Colors.white25,
    subdue: jigsawV2Colors.white50,
    base: jigsawV2Colors.white100,
    highlight: jigsawV2Colors.white200,
  },

  neutral: {
    subdue: jigsawV2Colors.neutral900,
    base: jigsawV2Colors.neutral800,
    highlight: jigsawV2Colors.neutral700,
    moreHighlight: jigsawV2Colors.neutral600,
    mostHighlight: jigsawV2Colors.neutral400,
  },
};

const outline = {
  special: {
    base: jigsawV2Colors.purple500V2,
  },
  error: {
    base: jigsawV2Colors.red500V2,
  },
  positive: {
    base: jigsawV2Colors.green500V2,
  },
  monetize: {
    base: jigsawV2Colors.yellow500V2,
  },

  neutral: {
    mostSubdue: jigsawV2Colors.white25,
    moreSubdue: jigsawV2Colors.white50,
    subdue: jigsawV2Colors.white100,
    base: jigsawV2Colors.white200,
    highlight: jigsawV2Colors.white500,
    moreHighlight: jigsawV2Colors.white700,
    mostHighlight: baseColors.white,
  },
};

const text = {
  link: {
    base: jigsawV2Colors.blue500V2,
  },
  monetize: {
    base: jigsawV2Colors.yellow500V2,
  },
  info: {
    base: jigsawV2Colors.blue400V2,
  },
  positive: {
    base: jigsawV2Colors.green500V2,
  },
  error: {
    base: jigsawV2Colors.red400V2,
  },
  warning: {
    base: jigsawV2Colors.orange500V2,
  },
  special: {
    base: jigsawV2Colors.purple500V2,
  },

  neutral: {
    moreSubdue: jigsawV2Colors.white300,
    subdue: jigsawV2Colors.white500,
    base: jigsawV2Colors.white700,
    highlight: baseColors.white,
  },

  inverted: {
    moreSubdue: jigsawV2Colors.black200,
    subdue: jigsawV2Colors.black600,
    base: baseColors.black,
  },
};

export const semanticColors = {
  surface,
  outline,
  text,
};

const flatSemanticColors = Object.entries(semanticColors).reduce(
  (acc, [elementType, topLevelValue]) => {
    Object.entries(topLevelValue).forEach(([priorityOrMood, midLevelValue]) => {
      Object.entries(midLevelValue).forEach(([level, value]) => {
        acc[`${elementType}-${priorityOrMood}-${level}`] = value;
      });
    });
    return acc;
  },
  {} as Record<string, string>
);

export const colors = {
  ...colorsToConsolidate,
  ...baseColors,
  ...jigsawV2Colors,
  ...flatSemanticColors,
};

export const externalBrandColors = {
  adp: {
    logoFill: "#FFFFFF",
  },
  amazon: {
    logoBackground: "#FFFFFF",
  },
  americanExpress: {
    logoBackground: "#016FD0",
    fillColor: "#FFFFFF",
  },
  angelList: {
    logoBackground: "#000000",
    fillColor: "#000000",
  },
  bankOfAmerica: {
    logoBackground: "#000000",
  },
  billDotCom: {
    orangePantone: "#FF5A0A",
    atomicTangerine: "#FF9C6C",
    logoBackground: "#EFEFF0",
  },
  bluevine: {
    logoBackground: "#FFFFFF",
  },
  brex: {
    logoBackground: "#171717",
    fillColor: "#F8F8FA",
  },
  chase: {
    logoBackground: "#0659A5",
    logoBackgroundBrandIcons: "#FFFFFF",
    logoFill: "#FFFFFF",
    altLogoFill: "#126BC5",
  },
  citi: {
    logoBackground: "#FFFFFF",
  },
  captable: {
    logoBackground: "#FD9800",
    fillColor: "#FFFFFF",
  },
  carta: {
    logoBackground: "#1B98ED",
    logoBackgroundBrandIcons: "#E3EEF3",
    logoFill: "#FFFFFF",
  },
  central: {
    logoBackground: "#1A0DAB",
  },
  causal: {
    logoBackground: "#FFFFFF",
    logoFill: "#C3D9F3",
  },
  daydream: {
    logoBackground: "#FFFFFF",
    logoFill: "#FFFFFF",
    brandColor: "#FF50BC",
  },
  deel: {
    logoBackground: "#FFFFFF",
    logoFill: "#FFFFFF",
    brandColor: "#15357A",
  },
  elanCreditCard: {
    logoBackground: "#FFFFFF",
  },
  every: {
    logoBackground: "#161B2C",
    logoFill: "#FFFFFF",
    brandColor: "#3F69FF",
  },
  expensify: {
    logoBackground: "#0B1B34",
    brandColorBlue: "#2EAAE2",
    brandColorGreen: "#2ECB70",
    brandColorOrange: "#FFC600",
    brandColorWhite: "#ffffff",
    brandColorGray: "#313E48",
  },
  firstNationalBankOfOmaha: {
    logoBackground: "#FFFFFF",
  },
  firstRepublic: {
    logoBackground: "#FFFFFF",
    brandColor: "#AD861E",
  },
  google: {
    logoBackground: "#FFFFFF",
    logoFill: "#FFFFFF",
  },
  gusto: {
    logoBackground: "#F45D48",
    backgroundColor: "#FFFFFF",
  },
  justworks: {
    logoBackground: "#1E98FF",
  },
  ltse: {
    logoBackground: "#110837",
  },
  meow: {
    logoBackground: "#FFFFFF",
    logoBackgroundBrandIcons: "#0C1D37",
    brandColor: "#FF6531",
    roundedBackground: "#F2DFD6",
  },
  mercury: {
    logoBackground: "#5466F9",
    logoFill: "#FFFFFF",
  },
  novo: {
    logoBackground: "#FFFFFF",
  },
  paypal: {
    logoBackground: "#FFFFFF",
  },
  pnc: {
    logoBackground: "#FFFFFF",
  },
  plaid: {
    logoBackground: "#111111",
    fillColor: "#FFFFFF",
  },
  quickbooks: {
    logoBackground: "#2CA01C",
    secondaryIconFill: "#81FFBB",
    fillColor: "#FFFFFF",
  },
  ramp: {
    logoBackground: "#F7FE8B",
    logoBackgroundBrandIcons: "#E4F222",
    fillColor: "#000000",
  },
  rho: {
    logoBackground: "#151716",
  },
  relay: {
    logoBackground: "#004822",
  },
  runway: {
    logoBackground: "#F9A600",
    fillColor: "#192227",
  },
  rippling: {
    logoBackground: "#FCB315",
    fillColor: "#502D3C",
  },
  salesforce: {
    logoBackground: "#00A1E0",
  },
  stripe: {
    logoBackground: "#6772E5",
    logoBackgroundBrandIcons: "#635BFF",
    backgroundColor: "#FFFFFF",
  },
  sturppy: {
    logoFill: "#FFFFFF",
    backgroundColor: "gold",
  },
  stripeAtlas: {
    logoBackground: "#FF7600",
  },
  svb: {
    logoBackground: "#003149",
    logoBackgroundBrandIcons: "#FFFFFF",
    legacyBackground: "#F8F8FA",
    legacyBrandColor: "#00C0FF",
  },
  slack: {
    logoBackground: "#FFFFFF",
    brandRed: "#E01E5A",
    brandGreen: "#2EB67D",
    brandYellow: "#ECB22E",
    brandBlue: "#36C5F0",
  },
  trinet: {
    logoBackground: "#0072CE",
    logoBackgroundBrandIcons: "#0B0134",
  },
  usBank: {
    logoBackground: "#FFFFFF",
  },
  wellsFargo: {
    logoBackground: "#D71E28",
  },
  wise: {
    logoBackground: "#9EE771",
  },
};
