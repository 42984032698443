import * as Types from '../types';

import { CalendarMonthString, CalendarDateTimeString, CalendarDateString } from "scalars";
import { gql } from '@apollo/client';
export type CardFragment = { __typename?: 'Card', id: any, accountId: any, holderName: string, lastFour: string };

export const CardFragmentDoc = gql`
    fragment card on Card {
  id
  accountId
  holderName
  lastFour
}
    `;