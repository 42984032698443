import { styled } from "@puzzle/theme";

export const StatusTag = styled("div", {
  flexGrow: 0,
  display: "inline-flex",
  alignItems: "center",
  px: "$0h",
  py: 1,
  borderRadius: "$1",
  fontSize: "$bodyXS",
  fontWeight: "$bold",

  variants: {
    variant: {
      default: {
        color: "$neutral200",
        backgroundColor: "$black",
      },

      warning: {
        color: "#69470F",
        backgroundColor: "#F0E0C7",
      },

      notice: {
        color: "$black",
        backgroundColor: "$neutral400",
      },

      success: {
        color: "$black",
        backgroundColor: "$green600",
      },

      danger: {
        color: "$neutral50",
        backgroundColor: "$red500",
      },
    },

    faded: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      variant: "default",
      faded: true,
      css: {
        color: "$neutral500",
      },
    },
  ],

  defaultVariants: {
    variant: "default",
    faded: false,
  },
});
