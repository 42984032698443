import { colors } from "./colors";
import rgba from "polished/lib/color/rgba";

export const gradients = {
  // Used 3 times
  labelLight: `linear-gradient(180deg, #343547 0%, ${colors.mauve700} 100%)`,
  // Used 2 times
  labelDark: `linear-gradient(180deg, #343547 0%, #22222E 100%)`,
  // Used 1 time
  blackMask: `linear-gradient(to bottom, black 0%, black 35%, transparent 100%)`,
  // Used 2 times
  purpleToLightPurple: `linear-gradient(0deg, ${rgba(colors.purple900, 0.25)} 0%, ${rgba(colors.purple500, 0.25)} 100%)`,
  // Used 1 time
  blueToPurple: `linear-gradient(to right, $blue500, $purple500)`,
  // Used 1 time
  purpleToGreen: `linear-gradient(116.54deg, ${rgba(colors.purple600, 0.1)} 10.36%, ${rgba(colors.greenalpha, 0.1)} 92.85%)`,
  // Used 1 time
  purpleToGreenAlt: `linear-gradient(90deg, ${rgba(colors.purple600, 0.24)} 0%, ${rgba(colors.green600, 0.24)} 100%)`,
  // Used 1 time
  purpleToGreenToBlue: `linear-gradient(117deg, ${rgba(colors.purple600, 0.1)} 10.36%, ${rgba(colors.greenalpha, 0.1)} 92.85%), ${colors.mauve800}`,
  // Used 3 times
  purpleToGreenToMauve: `linear-gradient(117deg, ${rgba(colors.purple600, 0.2)} 10.36%, ${rgba(colors.greenalpha, 0.2)} 92.85%), ${colors.mauve900}`,
  // Used 2 times
  blackToBlackToMauve: `linear-gradient(0deg, ${colors.black200} 0%, ${colors.black200} 100%), ${colors.mauve900}`,
  // Used 1 time
  blackToBlackToBlue: `linear-gradient(0deg, ${colors.black200} 0%, ${colors.black200} 100%), #211F33`,
  // Used 1 time
  blackToBlackToBlueAlt: `linear-gradient(0deg, ${colors.black50} 0%, ${colors.black50} 100%), #1A192A`,
  // Used 1 time
  blackToBlackToPurple: `linear-gradient(180deg, ${rgba(colors.black, 0)} 33.85%, ${colors.black200} 100%), ${rgba(colors.purple700, 0.4)}`,
  // Used 1 time
  mauveToBlue: `linear-gradient(0deg, ${colors.mauve900} 0%, ${rgba(colors.neutral900, 0.8)} 100%)`,
  // Used 1 time
  transparentToBottomBlack: `linear-gradient(to bottom, transparent, ${colors.black200})`,
  // Used 1 time
  transparentToTopBlack: `linear-gradient(to top, transparent, ${colors.black200})`,

  // Used 1 time
  greyToBlue: `linear-gradient(0deg, ${colors.white50} 0%, ${colors.white50} 100%), #1A192A`,
  // Used 2 times
  greyToBlueishToGrey: `linear-gradient(-75deg, ${rgba(colors.neutral300, 0)} 0%, ${rgba(colors.neutral300, 0.3)} 51.28%, ${rgba(colors.neutral300, 0)} 100%)`,
  // Used 1 time
  transparentToMauveBottom: `linear-gradient(to bottom, ${colors.mauve800} 0%, ${colors.mauve800} 100%)`,
  // Used 1 time
  purpleToTeal: `linear-gradient(135deg, ${colors.purple600} 0%, ${colors.white} 50%, ${colors.green300} 100%)`,
  // Used 1 time
  radialPurpleToBlack: `radial-gradient(59.63% 59.63% at 50% 0%, ${rgba(colors.purple900, 0.72)} 0%, ${rgba(colors.mauve900, 0)} 100%), #000211`,
  // Used 1 time
  radialGreyToGrey: `radial-gradient(circle farthest-side, #DFE1E4, #EDEFF5)`,
  // Used 1 time
  radialPurpleToBlue: `radial-gradient(circle farthest-side, #6A6379, ${colors.mauve800})`,
};

export const shadows = {
  onboardingPurpleBlur: `0px 0px 12px ${colors.purple600}`,
  purpleButtonBlur: `0px 0px 8px ${colors.purple600}`,
};
