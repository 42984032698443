import {
  InMemoryCache,
  NormalizedCacheObject,
  Reference,
  defaultDataIdFromObject,
} from "@apollo/client";
import introspectionQueryResultData from "graphql/introspectionQueryResultData";
import { CompanyFeatures, LedgerView, BillStatus, BillCurrentness } from "graphql/types";
import { concatWrappedPagination } from "./pagination";

const toShortId = (id?: string, prefix?: string) => {
  if (!id) return "";
  const shortId = id.slice(-6).toUpperCase();

  return prefix ? `${prefix}${shortId}` : shortId;
};

export default function createCache(initialState: NormalizedCacheObject) {
  return new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    typePolicies: {
      /*
        Below, keyFields builds a new cache ID for LedgerReportLine.
        This is because the id provided by LedgerReportLine is not unique.
        Issue: https://linear.app/puzzlefin/issue/PER-288/[be]-custom-columns-are-sending-back-the-same-ids-for-lines-used-in
      */
      LedgerReportLine: {
        keyFields: (ledgerReportLine) => {
          // Extract columnKeys from balanceByColumn array if it exists
          const columnKeys =
            ledgerReportLine.balanceByColumn && Array.isArray(ledgerReportLine.balanceByColumn)
              ? ledgerReportLine.balanceByColumn.map((col) => col.columnKey).join(",")
              : "";

          // Create a uniquely identifying object and stringify it
          return JSON.stringify({
            LedgerReportLine: {
              columns: columnKeys,
              id: ledgerReportLine.id || "",
              parentId: ledgerReportLine.parentId || "",
              lineType: ledgerReportLine.lineType || "",
              accountNumber: ledgerReportLine.accountNumber || "",
              title: ledgerReportLine.title || "",
            },
          });
        },
      },

      // Redirect queries to the cache if they refer to the same object, e.g. list vs detail
      // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
      Query: {
        fields: {
          checklist(_, { args, toReference }) {
            return toReference({
              __typename: "Checklist",
              id: args ? `${args.companyId}-${args.month}` : undefined,
            });
          },

          transaction(_, { args, toReference }) {
            return toReference({
              __typename: "Transaction",
              id: args?.id,
            });
          },

          userCategorizationRule(_, { args, toReference }) {
            return toReference({
              __typename: "UserCategorizationRule",
              id: args?.id,
            });
          },

          reconciliation(_, { args, toReference }) {
            return toReference({
              __typename: "Reconciliation",
              id: args?.id,
            });
          },

          manualJournalEntry(_, { args, toReference }) {
            return toReference({
              __typename: "ManualJournalEntry",
              id: args?.id,
            });
          },

          categories: {
            keyArgs: ["input", ["companyId", "context"]],
          },

          userCategorizationRules: concatWrappedPagination(
            ["input", ["companyId", "filter", "sortBy"]],
            "rules",
            (args) => args?.input.page.after
          ),

          implicitRules: concatWrappedPagination(
            ["input", ["companyId", "filterBy", "sortBy"]],
            "nodes",
            (args) => args?.input.page.after
          ),

          integrations: {
            merge: true,
            keyArgs: ["companyId"],
          },

          customers: concatWrappedPagination(
            ["companyId", "filterBy", "sortBy"],
            "items",
            (args) => args?.page?.after
          ),

          products: {
            merge: false,
          },

          vendors: {
            merge: false,
          },

          features: {
            merge: false,
          },

          auditLogs: concatWrappedPagination(
            ["input", ["companyId", "filterBy", "sortBy"]],
            "nodes",
            (args) => args?.after
          ),

          pagedVendorExpenseList: concatWrappedPagination(
            ["input", ["companyId", "sort"]],
            "items",
            (args) => args?.input.page.after,
            false
          ),
        },
      },

      PlaidIntegration: {
        merge: true,
      },

      PlaidInitializationInfo: {
        merge: true,

        fields: {
          connectInfo: {
            merge: true,
          },
        },
      },

      Company: {
        fields: {
          transactions: concatWrappedPagination(
            // TODO deprecate transactions, switch to "items"
            ["filterBy", "sortBy"],
            "nodes"
          ),

          financialSummary: {
            // The merge function prevents us from overwriting the data in financialSummary or its metrics
            // This is like merge: true, but handling nested objects
            merge(existingCacheData = {}, incomingCacheData) {
              return {
                ...existingCacheData,
                ...incomingCacheData,
                metrics: {
                  ...existingCacheData.metrics,
                  ...incomingCacheData.metrics,
                },
              };
            },
          },

          vendors: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "items",
            (args) => args?.page?.after
          ),

          manualJournalEntries: concatWrappedPagination(["filterBy"]),

          bills: concatWrappedPagination(["filterBy"], "items", (args) => args?.page?.after),

          apAging: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "items",
            (args) => args?.page?.after
          ),

          arAging: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "items",
            (args) => args?.page?.after
          ),

          ledgerReconciliations: concatWrappedPagination(
            ["filterBy"],
            "items",
            (args) => args?.page?.after
          ),

          invoices: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "items",
            (args) => args?.page?.after
          ),

          contractRevenueSchedules: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "items",
            (args) => args?.page?.after
          ),

          contractLines: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "contractLines",
            (args) => args?.page?.after
          ),

          payrolls: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "nodes",
            (args) => args?.page?.after
          ),

          integrationConnections: {
            merge: false,
          },

          ledgerReconciliationBasis: {
            read(_, { readField }) {
              return readField<CompanyFeatures>("features")?.ledgerReconciliationCashView
                ? LedgerView.Cash
                : LedgerView.Accrual;
            },
          },

          accrualRevenueAnalysisByCustomer: concatWrappedPagination(
            ["input", ["filter", "sort"]],
            "customers",
            (args) => args?.input.page?.after
          ),

          accrualRevenueAnalysisByCoaKey: concatWrappedPagination(
            ["input", ["filter", "sort"]],
            "coaKeyMetrics",
            (args) => args?.input.page?.after
          ),

          fixedAssets: concatWrappedPagination(["filterBy"], "items", (args) => args?.page?.after),

          prepaids: concatWrappedPagination(["filterBy"], "items", (args) => args?.page?.after),

          squashingPolicies: concatWrappedPagination(
            ["input", ["filterBy"]],
            "policies",
            (args) => args?.input.page?.after
          ),

          subLedgers: concatWrappedPagination(
            ["input", ["filterBy"]],
            "nodes",
            (args) => args?.page?.after
          ),

          stripeValidationRuns: concatWrappedPagination(
            ["filterBy"],
            "nodes",
            (args) => args?.page?.after
          ),

          accuracyChecks: concatWrappedPagination(
            ["filterBy"],
            "items",
            (args) => args?.page?.after
          ),
        },
      },

      Transaction: {
        fields: {
          splits: {
            merge: false,
          },

          activity: {
            merge: false,
          },

          documentation: {
            merge: false,
          },

          linkedBills: {
            merge: false,
          },
        },
      },

      TransactionDetail: {
        fields: {
          classSegments: {
            merge(existing: Reference[], incoming: Reference[]) {
              return incoming;
            },
          },
        },
      },
      Bill: {
        fields: {
          displayStatus: {
            read(_, { readField }) {
              const status = readField<BillStatus>("status");
              const currentness = readField<BillCurrentness>("currentness");

              return status === BillStatus.Posted ? currentness : status;
            },
          },
          shortId: {
            read(_, { readField }) {
              return toShortId(readField<string>("id"));
            },
          },
          documents: {
            merge: false,
          },
          activity: {
            merge: false,
          },
        },
      },

      Invoice: {
        fields: {
          shortId: {
            read(_, { readField }) {
              const externalId = readField<string>("externalId");
              const id = readField<string>("id");

              return externalId ?? toShortId(id);
            },
          },
        },
      },

      LedgerReconciliation: {
        fields: {
          storedFiles: {
            merge: false,
          },
        },
      },

      Category: {
        keyFields: ["permaKey"],
      },

      ProductPaginator: {
        fields: {
          items: {
            merge: false,
          },
        },
      },

      CustomerPaginator: {
        fields: {
          items: {
            merge: false,
          },
        },
      },

      RevenueItem: {
        fields: {
          periods: {
            merge: false,
          },
        },
      },

      ManualJournalEntry: {
        fields: {
          shortId: {
            read(_, { readField }) {
              return toShortId(readField<string>("id"), "JE-");
            },
          },
          documents: {
            merge: false,
          },
          activity: {
            merge: false,
          },
        },
      },

      ManualJournalEntryItem: {
        fields: {
          classSegments: {
            merge(existing: Reference[], incoming: Reference[]) {
              return incoming;
            },
          },
        },
      },

      ManualJournalEntryGroup: {
        fields: {
          shortId: {
            read(_, { readField }) {
              return toShortId(readField<string>("id"), "JE-");
            },
          },
        },
      },

      LedgerJournal: {
        fields: {
          shortId: {
            read(_, { readField }) {
              return toShortId(readField<string>("id"), "JE-");
            },
          },
        },
      },

      LedgerEvent: {
        fields: {
          shortExternalId: {
            read(_, { readField }) {
              return toShortId(readField<string>("externalId"));
            },
          },
        },
      },

      Reconciliation: {
        fields: {
          transactions: concatWrappedPagination(
            ["filterBy", "sortBy"],
            "nodes",
            (args) => args?.input?.after
          ),
        },
      },

      AccountingConfiguration: {
        fields: {
          shortId: {
            read(_, { readField }) {
              return toShortId(readField<string>("id"));
            },
          },
        },
      },
    },
    // Fix for issue with caching aliases https://github.com/apollographql/apollo-client/issues/10599
    // Supposedly won't be an issue in Apollo Client v4
    dataIdFromObject(object, context) {
      if (
        [
          "InvoiceLine",
          "InvoiceDiscountLine",
          "InvoiceShippingLine",
          "InvoiceTaxLine",
          "BillLine",
        ].includes(object.__typename || "")
      ) {
        return ["id"];
      }

      return defaultDataIdFromObject(object, context);
    },
  }).restore(initialState);
}
