/* eslint-disable react/display-name */
import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { styled, keyframes, css, CSSProps, gradients } from "@puzzle/theme";
import { useInDialogContext } from "./InDialogContext";

import { MODAL_ROOT_ID } from "./constants";
import { IS_CLIENT } from "./utils/environment";

const fadeIn = keyframes({
  from: { opacity: 0, transform: "scale(0.8)" },
  to: { opacity: 1, transform: "scale(1)" },
});

const fadeOut = keyframes({
  from: { opacity: 1, transform: "scale(1)" },
  to: { opacity: 0, transform: "scale(0.8)" },
});

const styles = {
  Arrow: css({
    fill: "$rhino700",
  }),

  Content: css({
    overflow: "hidden",
    background: "$rhino700",
    border: "1px solid $colors$black50",
    borderRadius: "$1",
    fontSize: "12px",
    lineHeight: "14px",
    outline: "none",
    // Testing fixing issue in Dev
    pointerEvents: "auto",

    transformOrigin: "var(--radix-popover-content-transform-origin)",

    '&[data-state="open"]': {
      animation: `${fadeIn} 150ms cubic-bezier(0.4, 0, 0.2, 1)`,
    },

    '&[data-state="closed"]': {
      animation: `${fadeOut} 150ms cubic-bezier(0.4, 0, 0.2, 1)`,
    },

    variants: {
      dark: {
        true: {
          background: "#242430",
        },
        false: {},
      },
    },
  }),

  Title: css({
    display: "flex",
    alignItems: "center",
    padding: "$0h $2",

    defaultVariants: {
      variant: "default",
    },

    variants: {
      variant: {
        // Sometimes there's no background...
        minimal: {
          borderBottom: "1px solid #272532",
          fontSize: "12px",
          lineHeight: "18px",
          letterSpacing: "0.2px",
          color: "$neutral400",
        },
        dark: { background: gradients.labelDark },
        default: {
          background: gradients.labelLight,
          color: "$neutral500",
        },
      },
    },
  }),

  Body: css({
    padding: "$1h $2 $2",
  }),
};

const Arrow = styled(PopoverPrimitive.Arrow, styles.Arrow);
const Content = styled(PopoverPrimitive.Content, styles.Content);
const Title = styled("div", styles.Title);
const Body = styled("div", styles.Body);

const Anchor = PopoverPrimitive.Anchor;
const Trigger = PopoverPrimitive.Trigger;
const Close = PopoverPrimitive.Close;

export type PopoverProps = React.ComponentProps<typeof PopoverPrimitive.Root> &
  React.ComponentProps<typeof PopoverPrimitive.Content> &
  CSSProps & {
    trigger?: React.ReactNode;
    anchor?: React.ReactNode;
    arrow?: boolean;
    width?: number;
  };

export const Popover = Object.assign(
  React.forwardRef<HTMLDivElement, PopoverProps>(
    (
      {
        defaultOpen,
        open,
        onOpenChange,
        modal,
        children,
        trigger,
        anchor,
        css,
        arrow = false,
        sideOffset = 8,
        ...props
      },
      ref
    ) => {
      const isInDialog = useInDialogContext();
      const modalRoot = IS_CLIENT ? document.getElementById(MODAL_ROOT_ID) : null;

      return (
        <PopoverPrimitive.Root
          data-testid="@puzzle/ui/popover/root"
          defaultOpen={defaultOpen}
          open={open}
          onOpenChange={onOpenChange}
          modal={modal}
        >
          {/* @ts-ignore behavior is correct, type is wrong */}
          {trigger && <PopoverPrimitive.Trigger asChild>{trigger}</PopoverPrimitive.Trigger>}
          {anchor && <PopoverPrimitive.Anchor>{anchor}</PopoverPrimitive.Anchor>}

          <PopoverPrimitive.Portal container={modalRoot}>
            <Content
              data-testid="@puzzle/ui/popover/content"
              dark={isInDialog}
              sideOffset={sideOffset}
              // This fixes top-start/top-end so the arrow is aligned with the trigger
              alignOffset={arrow ? -20 : 0}
              collisionPadding={4}
              {...props}
              ref={ref}
              css={css}
            >
              {children}
              {/* @ts-ignore behavior is correct, type is wrong */}
              {arrow && <Arrow width={11} height={5} />}
            </Content>
          </PopoverPrimitive.Portal>
        </PopoverPrimitive.Root>
      );
    }
  ),
  {
    Title,
    Content,
    Body,
    Close,
    Trigger,
    Anchor,
    Arrow,
    styles,
  }
);
