import React from "react";
import { styled } from "@puzzle/theme";
import { Input } from "./Input";
import { useFieldContext } from "./Field";

const Root = styled("div", {
  display: "flex",
  flexDirection: "row",
  borderRadius: "$1",

  // Supports a potential wrapper around Input
  "& > *": {
    width: "100%",
    boxShadow: "none",

    [`&:not(:first-child)`]: {
      [`&${Input}, ${Input}`]: {
        borderLeftWidth: "0 !important",
      },
    },

    "&:not(:first-child):not(:last-child)": {
      [`&${Input}, ${Input}`]: {
        borderRadius: "0 !important",
      },
    },

    "&:first-child:not(:only-child)": {
      [`&${Input}, ${Input}`]: {
        borderTopRightRadius: "0 !important",
        borderBottomRightRadius: "0 !important",
      },
    },

    "&:last-child:not(:only-child)": {
      [`&${Input}, ${Input}`]: {
        borderTopLeftRadius: "0 !important",
        borderBottomLeftRadius: "0 !important",
      },
    },
  },

  [`&:hover ${Input}`]: {
    $$borderColor: "$colors$neutral500",
  },

  [`&:focus-within ${Input}`]: {
    $$borderColor: "$colors$neutral300",
  },

  [`${Input}:focus-within`]: {
    borderBottomColor: "$green600",
  },

  [`${Input}`]: {
    $$boxShadowColor: "transparent !important",
  },

  variants: {
    feedback: {
      positive: {
        [`&, ${Input}`]: {
          $$borderColor: "$colors$green600 !important",
        },
      },

      negative: {
        [`&, ${Input}`]: {
          $$borderColor: "$colors$red600 !important",
        },
      },
      neutral: {},
    },

    active: {
      true: {
        [`&, &:hover ${Input}, ${Input}`]: {
          $$borderColor: "$colors$neutral300",
        },
      },
    },
  },
});

export const ControlGroup = ({
  children,
  feedback,
  ...props
}: React.ComponentPropsWithoutRef<typeof Root>) => {
  const fieldContext = useFieldContext();

  return (
    <Root feedback={feedback || fieldContext.feedback} {...props}>
      {children}
    </Root>
  );
};

ControlGroup.toString = Root.toString;
