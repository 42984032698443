import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { format } from "date-fns/format";
import { useToggle } from "react-use";
import { Calendar } from "@puzzle/icons";
import { colors } from "@puzzle/theme";
import { styled, CSS } from "@puzzle/theme";
import {
  DateRangePickerCalendar,
  DateRangePickerCalendarProps,
  AllowEmpty,
  RangeValue,
} from "./DateRangePickerCalendar";
import { GroupBy, RangePreset } from "./types";
import { Popover } from "../Popover";
import { getFormatForGroupBy } from "./utils";
import { Toolbar } from "../Toolbar";
import { CalendarDate, getLocalTimeZone } from "@internationalized/date";

const PillLabel = styled("span", {
  display: "flex",
  alignItems: "center",
  height: "100%",
  padding: "0 $1h",

  ":disabled > &": {
    "&, *": {
      color: "$neutral800",
    },
  },
});

const PresetLabel = styled(PillLabel, {
  color: "$neutral500",
  fontWeight: "$bold",
  fontSize: "14px",
  lineHeight: "18px",
});

export const DateLabel = styled(PillLabel, {
  gap: "10px",
  fontSize: "14px",
  lineHeight: "18px",
  letterSpacing: "0.2px",
  color: "$neutral50",

  variants: {
    isPlaceholder: {
      true: {
        color: "$neutral300",
      },
      false: {},
    },
  },
});

export enum DateRangePickerVariant {
  WithLabel = "withLabel",
  WithoutLabel = "withoutLabel",
  Minimal = "minimal",
}

// WARNING: This currently must be used in a Toolbar.
// If you need it elsewhere, make multiple variants/contexts that swap out the trigger's wrapper.
export function DateRangePicker<
  // Don't supply this generic! It will infer from allowEmpty={[false, false]}
  Empty extends AllowEmpty = [false, false],
>({
  initialPreset,
  preset: _preset,
  presets,
  onPresetChange,
  onChange,
  groupBy,
  showLabel = true,
  variant,
  placeholderText = "Select a date range",
  closeOnPresetChange = false,
  disabled,
  customLabelFormat,
  customTrigger,
  iconColor,
  css,
  popoverCss,
  ...props
}: DateRangePickerCalendarProps<Empty> & {
  initialPreset?: RangePreset;
  preset?: RangePreset;
  onPresetChange?: (preset: RangePreset) => void;
  groupBy?: `${GroupBy}`;
  /**
   * @deprecated Use variant prop instead.
   */
  showLabel?: boolean;
  variant?: DateRangePickerVariant;
  placeholderText?: string;
  closeOnPresetChange?: boolean;
  customLabelFormat?: string;
  customTrigger?: ReactNode;
  iconColor?: string;
  css?: CSS;
  popoverCss?: CSS;
}) {
  const [open, toggleOpen] = useToggle(false);
  const [presetState, setPresetState] = useState<RangePreset | undefined>(initialPreset);
  const preset = _preset ?? presetState;
  const setPreset = onPresetChange ?? setPresetState;

  const [displayedDate, setDisplayedDate] = useState<RangeValue<[true, true]>>([
    undefined,
    undefined,
  ]);

  const displayedStartDate = open ? displayedDate[0] : props.value?.[0];
  const displayedEndDate = open ? displayedDate[1] : props.value?.[1];

  const handleValueChange = useCallback(
    (value: RangeValue<Empty>, preset?: RangePreset) => {
      onChange?.(value, preset);
      if (
        closeOnPresetChange &&
        // Hacky. Maybe handleValueChange should include the reason for the change?
        !preset?.key.includes("custom")
      ) {
        toggleOpen(false);
      }
    },
    [closeOnPresetChange, onChange, toggleOpen]
  );

  const formatDate = useCallback(
    (date: CalendarDate) => {
      const formatter = customLabelFormat
        ? customLabelFormat
        : preset || groupBy
          ? getFormatForGroupBy(groupBy || preset?.groupBy)
          : "MMM dd, yyyy";

      return format(date.toDate(getLocalTimeZone()), formatter);
    },
    [groupBy, preset, customLabelFormat]
  );

  const getDatelabel = useMemo(() => {
    if (displayedStartDate && !displayedEndDate) {
      return formatDate(displayedStartDate);
    } else if (displayedStartDate && displayedEndDate) {
      return `${formatDate(displayedStartDate)} - ${formatDate(displayedEndDate)}`;
    } else {
      return placeholderText;
    }
  }, [displayedEndDate, displayedStartDate, formatDate, placeholderText]);

  const getMinimalDateLabel = useMemo(() => {
    const hasNoPreset = !preset && displayedStartDate && displayedEndDate;
    const hasCustomPreset = preset?.label === "Custom" && displayedStartDate && displayedEndDate;
    const hasNoEndDate = displayedStartDate && !displayedEndDate;
    const hasMatchingStartAndEndDates =
      displayedStartDate && displayedEndDate && displayedStartDate.compare(displayedEndDate) === 0;
    const isSingleDay = hasNoEndDate || hasMatchingStartAndEndDates;

    if (hasCustomPreset && isSingleDay) {
      return formatDate(displayedStartDate);
    }
    if (hasCustomPreset || hasNoPreset) {
      return `${formatDate(displayedStartDate)} - ${formatDate(displayedEndDate)}`;
    }
    if (preset?.label) {
      return preset?.label;
    }

    // The placeholder text never appears because we always have a preset. But just in case.
    return placeholderText;
  }, [displayedEndDate, displayedStartDate, formatDate, placeholderText]);

  const ICON_COLOR = disabled ? "currentColor" : (iconColor ?? colors.neutral400);

  const ToolbarButtonContents = () => {
    // We have deprecated showLabel in favor of a variant prop in the DateRangePicker component.
    // showLabel will still work, but variant is more extensible and will override showLabel if both are provided.
    // TODO: refactor out all cases of showLabel in favor of DateRangePickerVariant.WithLabel
    if (showLabel !== undefined && variant === undefined) {
      variant = showLabel ? DateRangePickerVariant.WithLabel : DateRangePickerVariant.WithoutLabel;
    }
    switch (variant) {
      case DateRangePickerVariant.WithLabel:
        return (
          <>
            <PresetLabel>{preset?.label ?? "Custom"}</PresetLabel>
            <DateLabel isPlaceholder={getDatelabel === placeholderText}>
              {getDatelabel}
              <Calendar size={14} color={ICON_COLOR} />
            </DateLabel>
          </>
        );

      case DateRangePickerVariant.WithoutLabel:
        return (
          <DateLabel isPlaceholder={getDatelabel === placeholderText}>
            {getDatelabel}
            <Calendar size={14} color={ICON_COLOR} />
          </DateLabel>
        );

      case DateRangePickerVariant.Minimal:
        return (
          <DateLabel isPlaceholder={getMinimalDateLabel === placeholderText}>
            <Calendar size={14} color={ICON_COLOR} />
            {getMinimalDateLabel}
          </DateLabel>
        );
    }
  };

  return (
    <Popover
      side="bottom"
      align="start"
      sideOffset={8}
      css={popoverCss}
      open={open}
      onOpenChange={toggleOpen}
      data-testid="datepicker-trigger-popover"
      trigger={
        customTrigger ? (
          customTrigger
        ) : (
          <Toolbar.Button
            data-testid="datepicker-trigger"
            variant="custom"
            disabled={disabled}
            css={css}
          >
            <ToolbarButtonContents />
          </Toolbar.Button>
        )
      }
    >
      <DateRangePickerCalendar<Empty>
        {...props}
        allowEmpty={props.allowEmpty}
        onChange={handleValueChange}
        presets={presets}
        preset={preset}
        onDisplayedValueChange={setDisplayedDate}
        onPresetChange={setPreset}
      />
    </Popover>
  );
}
