import { UrlObject } from "url";
import React from "react";
import { styled } from "@puzzle/theme";

import * as TabsPrimitive from "@radix-ui/react-tabs";

// Should next be used here? Seems okay for now.
import Link from "next/link";

const baseStyles = {
  padding: "$1h 0",
  borderBottom: "2px solid transparent",
  borderRadius: "$1 $1 0 0",
  color: "$neutral400",

  "&[data-state=active]": {
    borderBottomColor: "$green700",
    color: "$neutral100",
  },

  "&:hover": {
    color: "$neutral200",
  },

  "&:focus": {
    color: "$neutral100",
  },

  "&:disabled": {
    color: "$neutral800",
  },
};

const Root = styled(TabsPrimitive.Root, {
  defaultVariants: {
    variant: "default",
  },

  variants: {
    variant: {
      default: {},
      folder: {},
      minimal: {},
    },
  },
});

const List = styled(TabsPrimitive.List, {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "$4",

  defaultVariants: {
    variant: "default",
  },

  variants: {
    variant: {
      default: {
        borderBottom: "1px solid $mauve600",
      },
      folder: {
        gap: 0,
        borderBottom: "none",
        paddingLeft: "$2",
      },
      minimal: {},
    },
  },
});

const Trigger = styled(TabsPrimitive.Trigger, {
  appearance: "none",
  display: "flex",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  fontWeight: "$bold",
  textVariant: "$headingS",
  color: "$neutral500",
  userSelect: "none",
  cursor: "pointer",
  outline: "none",
  backgroundColor: "transparent",
  borderColor: "transparent",
  borderWidth: 0,
  transition: "all 0.1s ease-in",

  "&:disabled": {
    pointerEvents: "none",
  },

  defaultVariants: {
    variant: "default",
  },

  variants: {
    variant: {
      default: baseStyles,
      minimal: baseStyles,
      folder: {
        padding: "$2 $3",
        borderRadius: "$2 $2 0px 0px",
        fontSize: "$bodyS",
        lineHeight: "16px",
        letterSpacing: "0.5px",
        color: "$neutral400",
        textTransform: "uppercase",
        "&[data-state=active]": {
          color: "$neutral50",
          backgroundColor: "$rhino900",
          "@media print": {
            display: "block",
            color: "$black",
            fontWeight: "$bold",
          },
        },
        "@media print": {
          display: "none",
        },
      },
    },
  },
});

const Content = styled(TabsPrimitive.Content, {
  defaultVariants: {
    variant: "default",
  },

  variants: {
    variant: {
      default: {},
      minimal: {},
      folder: {
        "> *": {
          backgroundColor: "$rhino900",
        },
      },
    },
  },
});

const Actions = styled("div", {
  marginLeft: "auto",
});

export type TabItem = {
  title: React.ReactNode;
  value: string;
  href?: string | UrlObject;
  content?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  activePaths?: string[];
  dataTestId?: string;
};

export type TabsProps = React.ComponentProps<typeof Root> & {
  items: Readonly<TabItem[]>;
  actions?: React.ReactNode;
};

// NOTE: Vertical variant is not styled yet, nor is it used.
export const Tabs = ({ children, items, variant, actions, ...props }: TabsProps) => {
  return (
    <Root activationMode="manual" {...props} variant={variant}>
      <List variant={variant}>
        {items.map(({ href, value, dataTestId, ...item }) => {
          const Child = href ? "a" : "button";
          const content = (
            <Trigger value={value} key={value} disabled={item.disabled} variant={variant} asChild>
              <Child data-testid={dataTestId} onClick={item.onClick}>
                {item.title}
              </Child>
            </Trigger>
          );

          return href ? (
            <Link key={value} href={href} passHref legacyBehavior>
              {content}
            </Link>
          ) : (
            content
          );
        })}

        {actions && <Actions>{actions}</Actions>}
      </List>

      {children ??
        items.map(
          ({ value, content }) =>
            content && (
              <Content value={value} key={value} variant={variant}>
                {content}
              </Content>
            )
        )}
    </Root>
  );
};
