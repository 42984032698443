import React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { styled, keyframes } from "@puzzle/theme";
import { MODAL_ROOT_ID } from "./constants";
import { IS_CLIENT } from "./utils/environment";

const scaleIn = keyframes({
  from: { opacity: 0, transform: "scale(0.8)" },
  to: { opacity: 1, transform: "scale(1)" },
});

const scaleOut = keyframes({
  from: { opacity: 1, transform: "scale(1)" },
  to: { opacity: 0, transform: "scale(0.8)" },
});

const Arrow = styled(TooltipPrimitive.Arrow, {});

const Content = styled(TooltipPrimitive.Content, {
  maxWidth: 300,
  transformOrigin: "var(--radix-tooltip-content-transform-origin)",
  padding: "$1",
  color: "$neutral50",
  fontSize: 12,
  lineHeight: "18px",
  letterSpacing: "0.2px",
  borderRadius: 4,
  whiteSpace: "pre-wrap",

  defaultVariants: {
    variant: "dark",
  },

  variants: {
    variant: {
      bright: {
        backgroundColor: "#2D2B3D",
        [`${Arrow}`]: {
          fill: "#2D2B3D",
        },
      },
      dark: {
        backgroundColor: "$black",
        [`${Arrow}`]: {
          fill: "$black",
        },
      },
    },
  },

  '&[data-state="delayed-open"]': {
    animation: `${scaleIn} 150ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${scaleOut} 150ms cubic-bezier(0.4, 0, 0.2, 1)`,
  },
});

export type TooltipProps = Omit<
  React.ComponentProps<typeof TooltipPrimitive.Root>,
  "delayDuration" | "skipDelayDuration"
> &
  Omit<React.ComponentProps<typeof Content>, "content"> & {
    content?: React.ReactNode;
    arrow?: boolean;
    triggerProps?: React.ComponentProps<typeof TooltipPrimitive.Trigger>;
  };

export const Tooltip = ({
  defaultOpen,
  open,
  onOpenChange,
  children,
  content,
  arrow = true,
  triggerProps,
  ...props
}: React.PropsWithChildren<TooltipProps>) => {
  const modalRoot = IS_CLIENT ? document.getElementById(MODAL_ROOT_ID) : null;

  if (!content) {
    return <>{children}</>;
  }

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
        delayDuration={100}
      >
        <TooltipPrimitive.Trigger asChild {...triggerProps}>
          {children}
        </TooltipPrimitive.Trigger>

        <TooltipPrimitive.Portal container={modalRoot}>
          <Content
            side="top"
            sideOffset={4}
            align="center"
            alignOffset={arrow ? -23 : 0}
            {...props}
          >
            {content}
            {arrow && <Arrow offset={24} width={11} height={5} />}
          </Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
