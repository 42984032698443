import React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { styled } from "@puzzle/theme";
import { RadioIndicator, RadioItem } from "./RadioGroup";

const OptionLabel = styled("div", {
  fontSize: "$bodyM",
  fontWeight: "$bold",
  lineHeight: "16px",
  color: "$neutral500",
});

const OptionDescription = styled("div", {
  color: "$neutral600",
});

const RadioGroupRoot = styled(RadioGroupPrimitive.Root, {
  borderBottom: "1px solid $mauve680",
  margin: "0 -$3",
});

const RadioGroupItem = styled("label", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "$2",
  padding: "$2 $5",
  borderTop: "1px solid $mauve680",
  cursor: "pointer",

  "&:hover": {
    [`${OptionLabel}`]: {
      color: "$neutral400",
    },
    [`${OptionDescription}`]: {
      color: "$neutral400",
    },
  },

  '&[aria-checked="true"]': {
    [`${OptionLabel}`]: {
      color: "$neutral200",
    },
    [`${OptionDescription}`]: {
      color: "$neutral400",
    },
  },
});

type Option = { value: string; label: string; description: string };
export const DialogRadioGroup = React.memo(
  ({
    options,
    ...props
  }: React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & {
    options: Option[];
  }) => {
    return (
      <RadioGroupRoot {...props}>
        {options.map((o) => (
          <RadioGroupItem key={o.value} aria-checked={o.value === props.value}>
            <RadioItem value={o.value}>
              <RadioIndicator />
            </RadioItem>

            <div>
              <OptionLabel>{o.label}</OptionLabel>
              <OptionDescription>{o.description}</OptionDescription>
            </div>
          </RadioGroupItem>
        ))}
      </RadioGroupRoot>
    );
  }
);
